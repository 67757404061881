import { PatientAgeGroup } from '@headway/api/models/PatientAgeGroup';
import { ProviderFunction } from '@headway/api/models/ProviderFunction';
import { TimeOfDayFilter } from '@headway/api/models/TimeOfDayFilter';
import { UserSessionLocationPreference } from '@headway/api/models/UserSessionLocationPreference';
import { WeekdayFilter } from '@headway/shared/constants/search';

export const FREE_TEXT_SEARCH_FILTERS = [
  'availabilities',
  'ethnicities',
  'forChild',
  'frontEndCarrierId',
  'genders',
  'issues',
  'languages',
  'medium',
  'phoneConsults',
  'selectedModalities',
  'typeOfCare',
];

export const typeOfCareOptions = [
  {
    key: ProviderFunction.MEDICATION_MANAGEMENT,
    text: 'Medication management',
  },
  {
    key: ProviderFunction.TALK_THERAPY,
    text: 'Talk therapy',
  },
];

export const mediumOptions = [
  {
    key: UserSessionLocationPreference.IN_PERSON,
    text: 'In person',
  },
  {
    key: UserSessionLocationPreference.VIRTUAL,
    text: 'Video',
  },
];

export const patientAgeGroupOptions = [
  {
    key: PatientAgeGroup.INFANTS_TODDLERS,
    text: 'Therapists for my infant or toddler (1-6)',
  },
  {
    key: PatientAgeGroup.CHILDREN,
    text: 'Therapists for my child (6-12)',
  },
  {
    key: PatientAgeGroup.ADOLESCENTS,
    text: 'Therapists for my adolescent (13-17)',
  },
];

export const availabilityOptions = [
  {
    key: TimeOfDayFilter.MORNING,
    text: 'Morning (before 12pm)',
  },
  { key: TimeOfDayFilter.NOON, text: 'Noon (12pm - 2pm)' },
  { key: TimeOfDayFilter.AFTERNOON, text: 'Afternoon (2pm - 5pm)' },
  { key: TimeOfDayFilter.EVENING, text: 'Evening (after 5pm)' },
  {
    key: WeekdayFilter.WEEKDAY,
    text: 'Weekday',
  },
  { key: WeekdayFilter.WEEKEND, text: 'Weekend' },
];
