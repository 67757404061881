import React from 'react';

import { ContentText } from '@headway/helix/ContentText';

import { LogoLoader } from './LogoLoader';
import { theme } from './theme';

export class LogoLoaderWithText extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTextCounter: 0,
    };
  }

  componentDidMount() {
    const loadingTexts = this.props.loadingTexts;

    if (loadingTexts && loadingTexts.length) {
      this.timer = setInterval(
        () =>
          this.setState({
            currentTextCounter:
              (this.state.currentTextCounter + 1) % loadingTexts.length,
          }),
        4000
      );
    }
  }

  componentWillUnmount() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  render() {
    const { loadingTexts, loaderStroke, loaderFont, variant } = this.props;
    const { currentTextCounter } = this.state;

    return (
      <div
        css={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <LogoLoader css={{ height: '80px' }} stroke={loaderStroke} />
        {loadingTexts && loadingTexts.length ? (
          <>
            {variant === 'helix' ? (
              <div className="mt-[2rem]">
                <ContentText variant="body/medium">
                  {loadingTexts[currentTextCounter]}
                </ContentText>
              </div>
            ) : (
              <div
                css={{
                  fontFamily: loaderFont || theme.fontFamily.brandText,
                  marginTop: theme.space.xl2,
                }}
              >
                {loadingTexts[currentTextCounter]}
              </div>
            )}
          </>
        ) : undefined}
      </div>
    );
  }
}
