import { BankAccountVerificationStatus } from '@headway/api/models/BankAccountVerificationStatus';
import { PaymentMethodReadinessIssueAllPaymentMethodsExhaustedRetryCycleType } from '@headway/api/models/PaymentMethodReadinessIssueAllPaymentMethodsExhaustedRetryCycle';
import { PaymentMethodReadinessIssueBankAccountUnverifiedType } from '@headway/api/models/PaymentMethodReadinessIssueBankAccountUnverified';
import { PaymentMethodReadinessIssueCreditCardExpiredType } from '@headway/api/models/PaymentMethodReadinessIssueCreditCardExpired';
import { PaymentMethodReadinessIssueMissingType } from '@headway/api/models/PaymentMethodReadinessIssueMissing';
import { PaymentMethodReadinessIssuePreAuthChargeFailedType } from '@headway/api/models/PaymentMethodReadinessIssuePreAuthChargeFailed';
import { UserAppointmentReadiness } from '@headway/api/models/UserAppointmentReadiness';
import { UserPaymentMethodRead } from '@headway/api/models/UserPaymentMethodRead';
import { UserPaymentMethodType } from '@headway/api/models/UserPaymentMethodType';

export function getUserPaymentMethodName(
  userPaymentMethod: UserPaymentMethodRead | undefined
) {
  if (!!userPaymentMethod) {
    if (userPaymentMethod.type === UserPaymentMethodType.CARD) {
      return userPaymentMethod.card?.brand || 'Credit Card';
    } else if (userPaymentMethod.type === UserPaymentMethodType.BANK_ACCOUNT) {
      return userPaymentMethod?.bankAccount?.bankName || 'Bank Account';
    }
  }
  return 'Account';
}

export function getUserPaymentMethodLast4(
  userPaymentMethod: UserPaymentMethodRead | undefined
) {
  if (!!userPaymentMethod) {
    if (userPaymentMethod.type === UserPaymentMethodType.CARD) {
      return userPaymentMethod.card?.last4 || '';
    }
    return userPaymentMethod.bankAccount?.last4 || '';
  }
  return '';
}

export function getUserPaymentMethodVerificationStatus(
  userPaymentMethod: UserPaymentMethodRead
) {
  if (userPaymentMethod.type === UserPaymentMethodType.CARD) {
    return BankAccountVerificationStatus.VERIFIED;
  }

  return userPaymentMethod.bankAccount?.verificationStatus;
}

export function plaidStatusToBankAccountStatus(status?: string) {
  if (status === 'pending_automatic_verification') {
    return BankAccountVerificationStatus.PENDING_AUTOMATIC_VERIFICATION;
  } else if (status === 'pending_manual_verification') {
    return BankAccountVerificationStatus.PENDING_MANUAL_VERIFICATION;
  }

  return BankAccountVerificationStatus.VERIFIED;
}

export enum PaymentMethodStatus {
  UNVERIFIED = 'UNVERIFIED',
  VERIFIED = 'VERIFIED',
  ERROR = 'ERROR',
}

export const getPaymentMethodStatus = ({
  userPaymentMethod,
  issues = [],
}: {
  userPaymentMethod: UserPaymentMethodRead;
  issues?: UserAppointmentReadiness['paymentMethod'];
}) => {
  if (
    issues.some(
      ({ type }) =>
        type ===
        PaymentMethodReadinessIssueAllPaymentMethodsExhaustedRetryCycleType.ALL_PAYMENT_METHODS_EXHAUSTED_RETRY_CYCLE
    )
  ) {
    return PaymentMethodStatus.ERROR;
  }

  if (issues.length > 0 && userPaymentMethod.isDefault) {
    switch (issues[0].type) {
      case PaymentMethodReadinessIssueBankAccountUnverifiedType.BANK_ACCOUNT_UNVERIFIED:
        return PaymentMethodStatus.UNVERIFIED;
      case PaymentMethodReadinessIssueMissingType.MISSING_PAYMENT_METHOD:
      case PaymentMethodReadinessIssueCreditCardExpiredType.CREDIT_CARD_EXPIRED:
      case PaymentMethodReadinessIssuePreAuthChargeFailedType.PRE_AUTH_CHARGE_FAILED:
      default:
        return PaymentMethodStatus.ERROR;
    }
  }

  if (userPaymentMethod.type === UserPaymentMethodType.BANK_ACCOUNT) {
    const verificationStatus =
      userPaymentMethod.bankAccount?.verificationStatus;

    switch (verificationStatus) {
      case BankAccountVerificationStatus.PENDING_AUTOMATIC_VERIFICATION:
      case BankAccountVerificationStatus.PENDING_MANUAL_VERIFICATION:
        return PaymentMethodStatus.UNVERIFIED;
      case BankAccountVerificationStatus.VERIFICATION_FAILED:
        return PaymentMethodStatus.ERROR;
      case BankAccountVerificationStatus.VERIFIED:
      default:
        return PaymentMethodStatus.VERIFIED;
    }
  }

  if (userPaymentMethod.type === UserPaymentMethodType.CARD) {
    if (userPaymentMethod.card?.isExpired || !userPaymentMethod.isVerified) {
      return PaymentMethodStatus.ERROR;
    }
  }

  return PaymentMethodStatus.VERIFIED;
};
