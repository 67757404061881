/**
 * Mamba 🐍
 * Creating access to affordable therapy.
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { ReadinessIssueImpact } from './ReadinessIssueImpact';
export interface PaymentMethodReadinessIssueBankAccountUnverified {
    impact: ReadinessIssueImpact;

    metadata?: object;

    type: PaymentMethodReadinessIssueBankAccountUnverifiedType;

}


    export enum PaymentMethodReadinessIssueBankAccountUnverifiedType {
            BANK_ACCOUNT_UNVERIFIED = 'BANK_ACCOUNT_UNVERIFIED'
    }

