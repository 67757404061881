// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"3w6zqYoDm-6GM12CT54YW"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import * as Sentry from '@sentry/nextjs';
import axios from 'axios';

import {
  getMetadataForNetworkCallError,
  getSharedSentryClientConfig,
} from './utils/sentry';

const sharedConfig = getSharedSentryClientConfig();

Sentry.init({
  ...sharedConfig,
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0,
  ignoreErrors: [
    // https://stackoverflow.com/a/66260608
    'ResizeObserver loop limit exceeded',
    'ResizeObserver loop completed with undelivered notifications.',
  ],
  beforeSend(event, hint) {
    const error = hint?.originalException;
    if (axios.isAxiosError(error)) {
      const metadata = getMetadataForNetworkCallError(error);
      event.tags = { ...event.tags, ...metadata.tags };
      event.fingerprint = ['{{ default }}', ...metadata.fingerprints];
      event.message = metadata.message || event.message;
    }
    return event;
  },
  integrations: [
    Sentry.rewriteFramesIntegration({
      iteratee: (frame) => {
        if (frame.filename) {
          // we do this because the filename is URL encoded in the trace but the corresponding sourcemap is not URL encoded
          // which leads to Sentry not being able to find the sourcemap.
          // e.g., the filename in the trace will be `/_next/static/chunks/pages/search/%5B%5B...state%5D%5D-a39726f154380029.js`
          // but the sourcemap will be at `/_next/static/chunks/pages/search/[[...state]]-a39726f154380029.js`
          frame.filename = decodeURI(frame.filename);
        }
        return frame;
      },
    }),
  ],
  // ...
});
