import isNil from 'lodash/isNil';
import moment from 'moment';

import { EligibilityLookupNested } from '@headway/api/models/EligibilityLookupNested';
import { EligibilityLookupRead } from '@headway/api/models/EligibilityLookupRead';
import { FrontEndCarrierIdentifier } from '@headway/api/models/FrontEndCarrierIdentifier';
import { FrontEndCarrierRead } from '@headway/api/models/FrontEndCarrierRead';
import { InsuranceReadinessApproachingMaxSessionsType } from '@headway/api/models/InsuranceReadinessApproachingMaxSessions';
import { InsuranceReadinessIssueFrozenForCoordinationOfBenefitsType } from '@headway/api/models/InsuranceReadinessIssueFrozenForCoordinationOfBenefits';
import { InsuranceReadinessIssueFrozenForCoordinationOfBenefitsAwaitingUserActionType } from '@headway/api/models/InsuranceReadinessIssueFrozenForCoordinationOfBenefitsAwaitingUserAction';
import { InsuranceReadinessIssueHasNoTelehealthBenefitsType } from '@headway/api/models/InsuranceReadinessIssueHasNoTelehealthBenefits';
import { InsuranceReadinessIssueInactiveBenefitsType } from '@headway/api/models/InsuranceReadinessIssueInactiveBenefits';
import {
  InsuranceReadinessIssueInsufficientOrIncorrectInfo,
  InsuranceReadinessIssueInsufficientOrIncorrectInfoType,
} from '@headway/api/models/InsuranceReadinessIssueInsufficientOrIncorrectInfo';
import { InsuranceReadinessIssueManualVerificationRequiredType } from '@headway/api/models/InsuranceReadinessIssueManualVerificationRequired';
import { InsuranceReadinessIssueNoMentalHealthBenefitsType } from '@headway/api/models/InsuranceReadinessIssueNoMentalHealthBenefits';
import { InsuranceReadinessIssueOutOfNetworkType } from '@headway/api/models/InsuranceReadinessIssueOutOfNetwork';
import { InsuranceReadinessIssueTerminatedType } from '@headway/api/models/InsuranceReadinessIssueTerminated';
import { InsuranceReadinessNoRemainingSessionsType } from '@headway/api/models/InsuranceReadinessNoRemainingSessions';
import { InsuranceReadinessRemainingSessionsType } from '@headway/api/models/InsuranceReadinessRemainingSessions';
import { LookupStatus } from '@headway/api/models/LookupStatus';
import { NestedPatientReadForCalendar } from '@headway/api/models/NestedPatientReadForCalendar';
import { PatientInsuranceOrEAPStatus } from '@headway/api/models/PatientInsuranceOrEAPStatus';
import { ProviderFrontEndCarrierRead } from '@headway/api/models/ProviderFrontEndCarrierRead';
import { ProviderLicenseStateRead } from '@headway/api/models/ProviderLicenseStateRead';
import { UnitedStates } from '@headway/api/models/UnitedStates';
import { UserAppointmentReadiness } from '@headway/api/models/UserAppointmentReadiness';
import { UserInsuranceRead } from '@headway/api/models/UserInsuranceRead';
import { UserRead } from '@headway/api/models/UserRead';
import { WrapNetwork } from '@headway/api/models/WrapNetwork';
import {
  ANTHEM_IDS,
  BCBS_IDS,
  BCBS_MEMBER_ID_PATTERN,
  CARRIER_REGEX_MAP,
  DEFAULT_MEMBER_ID_PATTERN,
} from '@headway/shared/constants/carrierIds';
import {
  eligibilityEffectiveCarrierFor,
  isCarrierInHeadwayNetworkForState,
} from '@headway/shared/utils/carriers';
import { formatPrice } from '@headway/shared/utils/payments';
import { isAppointmentReady } from '@headway/shared/utils/providerFrontEndCarrier';
import { checkExhaustive } from '@headway/shared/utils/types';

/**
 * For our purposes, we will consider BCBS BlueCard plans to have at least 2 letters in the first 3 characters.
 * Though technically there are cases when there can be only 1, we have decided it is a small enough number
 * that it is acceptable until we can determine a way to guarantee whether a member is blue card.
 *
 * Note that this logic is repeated in mamba/app/shared/helpers/in_network.py is_blue_card
 */
export const isBlueCardPlan = (memberId: String) => {
  if (memberId.length < 3) {
    return false;
  }

  let numLetters = memberId.substring(0, 3).match(/[A-Za-z]/g)?.length;

  return !!numLetters && numLetters >= 2;
};

export const isFrontEndCarrierIdBcbs = (id: number) => {
  return BCBS_IDS.includes(id);
};

export const isBCBSBlueCard = (memberId: string, frontendCarrierId: number) => {
  return isFrontEndCarrierIdBcbs(frontendCarrierId) && isBlueCardPlan(memberId);
};

export const isValidBcbsPair = (
  providerFrontEndCarrier: ProviderFrontEndCarrierRead,
  patientFrontEndCarrierId: number
) => {
  const pfecCarrierId = providerFrontEndCarrier.frontEndCarrierId;
  if (
    pfecCarrierId === FrontEndCarrierIdentifier.BLUE_SHIELD_CALIFORNIA &&
    ANTHEM_IDS.includes(patientFrontEndCarrierId)
  ) {
    return false;
  }

  return !(
    pfecCarrierId === FrontEndCarrierIdentifier.ANTHEM_BLUE_CROSS_CALIFORNIA &&
    patientFrontEndCarrierId ===
      FrontEndCarrierIdentifier.BLUE_SHIELD_CALIFORNIA
  );
};

/* Returns true if: 
      - patient is eligible for BCBSMA or BCBSNJ wrap network
      - patient either doesn't have BlueCard or does have BlueCard but provider isn't credentialed 
        with a BCBS plan that isn't the BCBSMA or BCBSNJ wrap network
        (aka the patient's BlueCard doesn't apply)
  */
export const patientCanOnlySeeVirtualAppts = (
  patientUser: UserRead,
  providerFrontEndCarriers?: ProviderFrontEndCarrierRead[]
) => {
  const isEligibleForWrapNetwork =
    !!patientUser.activeUserInsurance &&
    !!patientUser.lastSearchedState &&
    getEligiblePatientWrapNetworks(
      patientUser.activeUserInsurance.billingFrontEndCarrierId,
      patientUser.lastSearchedState
    ).length > 0;

  const providerCredentialedWithBCBSNotWrap = providerFrontEndCarriers?.some(
    (pfec) =>
      isFrontEndCarrierIdBcbs(pfec.frontEndCarrierId) &&
      pfec.wrapNetwork !== WrapNetwork.BCBS_MA &&
      pfec.wrapNetwork !== WrapNetwork.BCBS_NJ &&
      pfec.wrapNetwork !== WrapNetwork.INDEPENDENCE_BC
  );
  const canUseBlueCardForInPerson =
    !!patientUser.activeUserInsurance?.memberId &&
    isBlueCardPlan(
      patientUser.activeUserInsurance?.correctedMemberId ||
        patientUser.activeUserInsurance?.memberId
    ) &&
    providerCredentialedWithBCBSNotWrap;

  return isEligibleForWrapNetwork && !canUseBlueCardForInPerson;
};

/* BCBSMA and BCBSNJ virtual only ("wrap") network applies to patients who:
    - have BCBSMA insurance and are outside of Massachusetts
    - or have BCBSNJ insurance and are outside of New Jersey
*/
export const isPatientEligibleForBCBSMassWrapNetwork = (
  patientFrontEndCarrierId: number,
  patientState: UnitedStates
) =>
  patientFrontEndCarrierId ===
    FrontEndCarrierIdentifier.BLUE_CROSS_BLUE_SHIELD_MASSACHUSETTS &&
  patientState !== UnitedStates.MASSACHUSETTS;
export const isPatientEligibleForHorizonBCBSNJWrapNetwork = (
  patientFrontEndCarrierId: number,
  patientState: UnitedStates
) =>
  patientFrontEndCarrierId ===
    FrontEndCarrierIdentifier.HORIZON_BLUE_CROSS_BLUE_SHIELD_NEW_JERSEY &&
  patientState !== UnitedStates.NEW_JERSEY;

export const isPatientEligibleForIBXWrapNetwork = (
  patientFrontEndCarrierId: number
) =>
  patientFrontEndCarrierId ===
  FrontEndCarrierIdentifier.INDEPENDENCE_BLUE_CROSS_PENNSYLVANIA;

export const getEligiblePatientWrapNetworks = (
  patientFrontEndCarrierId: number,
  patientState: UnitedStates
) => {
  const eligibleWrapNetworks = [];

  if (
    isPatientEligibleForBCBSMassWrapNetwork(
      patientFrontEndCarrierId,
      patientState
    )
  ) {
    eligibleWrapNetworks.push(WrapNetwork.BCBS_MA);
  }

  if (
    isPatientEligibleForHorizonBCBSNJWrapNetwork(
      patientFrontEndCarrierId,
      patientState
    )
  ) {
    eligibleWrapNetworks.push(WrapNetwork.BCBS_NJ);
  }
  if (isPatientEligibleForIBXWrapNetwork(patientFrontEndCarrierId)) {
    eligibleWrapNetworks.push(WrapNetwork.INDEPENDENCE_BC);
  }

  return eligibleWrapNetworks;
};

// Finds a front end carrier matching between a provider's carriers and the patient's carrier.
// This is either an exact match, or two BCBS carriers in case of BlueCard.
// In edge case of two BCBS carriers for one provider, take exact match if exists, else take earlier-credentialed.
//
// see comments in utils/carriers.ts (eligibilityEffectiveCarrierFor & isCarrierInHeadwayNetworkForState)
// for an explanation of why carriersById is a required argument here and why it's important.
export const findMatchingFrontendCarrier = (
  providerFrontEndCarriers?: ProviderFrontEndCarrierRead[],
  patientFrontEndCarrierId?: number,
  memberId?: string,
  latestEligibilityLookup?: EligibilityLookupNested | undefined,
  carrier?: FrontEndCarrierRead,
  excludeHiddenProviderCarriers: boolean = false,
  patientState: UnitedStates | null = null,
  isTelehealth: boolean = true
) => {
  if (!patientFrontEndCarrierId || !providerFrontEndCarriers) return undefined;
  const patientFrontEndCarrierEffectiveId =
    (carrier && eligibilityEffectiveCarrierFor(carrier).id) ||
    patientFrontEndCarrierId;

  let carriers = providerFrontEndCarriers
    ?.filter((providerCarrier) =>
      excludeHiddenProviderCarriers ? !providerCarrier.hiddenFromProfile : true
    )
    ?.filter(
      (providerCarrier) =>
        providerCarrier.frontEndCarrierId ===
          patientFrontEndCarrierEffectiveId ||
        (memberId &&
          isBlueCardPlan(memberId) &&
          isFrontEndCarrierIdBcbs(providerCarrier.frontEndCarrierId) &&
          isFrontEndCarrierIdBcbs(patientFrontEndCarrierId) &&
          isValidBcbsPair(providerCarrier, patientFrontEndCarrierId))
    )
    .sort(
      (pfc1, pfc2) =>
        moment(pfc1.credentialedOn || undefined).unix() -
        moment(pfc2.credentialedOn || undefined).unix()
    );

  // We need the patientState in order to determine patient elibility for wrap networks. If we don't have it,
  // we assume they are not eligible.
  if (patientState && isTelehealth) {
    // Gets a list of eligible wrap networks for the patient
    const eligibleWrapNetworks: WrapNetwork[] = getEligiblePatientWrapNetworks(
      patientFrontEndCarrierId,
      patientState
    );

    // Filters out any carriers with a wrapNetwork that are not in the list
    carriers = carriers.filter((providerCarrier) =>
      providerCarrier.wrapNetwork
        ? eligibleWrapNetworks.includes(providerCarrier.wrapNetwork)
        : true
    );
  } else {
    // Filter out any carriers that are a wrapNetwork since we assume
    // that all wrap networks should be telehealth
    carriers = carriers.filter((providerCarrier) =>
      providerCarrier.wrapNetwork ? false : true
    );
  }

  // if no carriers are credentialed, prefer an exact match:
  if (!carriers.some((c) => isAppointmentReady(c))) {
    const matchingCarrier = carriers.find((c) => {
      return c.frontEndCarrierId === patientFrontEndCarrierId;
    });
    if (matchingCarrier) {
      return matchingCarrier;
    }
  }

  // prefer an exact match if it's appointment ready:
  const matchingBluecard = carriers.find((c) => {
    return (
      isAppointmentReady(c) && c.frontEndCarrierId === patientFrontEndCarrierId
    );
  });
  if (matchingBluecard) {
    return matchingBluecard;
  }

  // We use their latest eligibility lookup to determine if they
  // are a medicare plan or not. If we are missing it return early.
  if (!latestEligibilityLookup) {
    return undefined;
  }

  // If they are a medicare plan, we don't want to match to a BCBS plan
  if (isUserInsuranceMedicareOrMedicaid(latestEligibilityLookup)) {
    return undefined;
  }

  // otherwise return the earliest credentialed carrier:
  if (carriers.length) {
    return carriers[0];
  }

  return undefined;
};

/**
 * Checks whether the patient is out of state with the provider.
 * If the patient has bcbs blue card insurance, ignore the state requirement.
 */
export const isOutOfState = (
  providerLicenseStates: ProviderLicenseStateRead[],
  lastSearchedState?: UnitedStates,
  activeUserInsurance?: Pick<
    UserInsuranceRead,
    'frontEndCarrierId' | 'memberId' | 'correctedMemberId'
  >
): boolean => {
  const providerStates = providerLicenseStates.map((pls) => pls.state);
  return (
    (lastSearchedState &&
      !providerStates.includes(lastSearchedState) &&
      activeUserInsurance &&
      !isBCBSBlueCard(
        activeUserInsurance.correctedMemberId ||
          activeUserInsurance.memberId ||
          '',
        activeUserInsurance.frontEndCarrierId //TODO SC-263184
      )) ||
    false
  );
};

/**
 * Returns true if a carrier has plans that can be accepted by Headway in a state. This is subtly
 * different from isCarrierInHeadwayNetworkForState because BCBS carriers allow BlueCard plans to
 * see providers who accept out-of-state BCBS carriers.
 * A true result does not necessarily mean that a client with this carrier can see a provider in
 * a given state, but a false result means they definitely cannot.
 * If no state is provided, returns true if there are ANY states for which the carrier can be
 * accepted.
 */
export const canCarrierBeAcceptedInState = (
  carrier: FrontEndCarrierRead,
  state?: UnitedStates
): boolean =>
  isFrontEndCarrierIdBcbs(carrier.id) ||
  isCarrierInHeadwayNetworkForState(carrier, state);

/**
 * The nature of the payments that a client is making for covered care at a particular stage of
 * their insurance.
 */
export enum PaymentStructure {
  // The client is paying towards a deductible.
  DEDUCTIBLE = 'DEDUCTIBLE',
  // The client’s costs are due to a copay.
  COPAY = 'COPAY',
  // The client's costs are due to coinsurance.
  COINSURANCE = 'COINSURANCE',
  // The client's costs come from both copay and coinsurance
  COPAY_COINSURANCE = 'COPAY_COINSURANCE',
  // The client pays nothing, usually because they hit an individual or family OOP max.
  FULLY_COVERED = 'FULLY_COVERED',
  // Error case.
  UNKNOWN = 'UNKNOWN',
}

export const PaymentStructureToCopyMap: Record<PaymentStructure, string> = {
  [PaymentStructure.DEDUCTIBLE]: 'Deductible',
  [PaymentStructure.COPAY]: 'Copay',
  [PaymentStructure.COINSURANCE]: 'Coinsurance',
  [PaymentStructure.COPAY_COINSURANCE]: 'Copay Coinsurance',
  [PaymentStructure.FULLY_COVERED]: 'Out-of-pocket maximum',
  [PaymentStructure.UNKNOWN]: 'Unknown',
};

/** Describes how a patient moves to the next stage of their insurance payments. */
export enum StageCompletionCriteria {
  // The client must contribute a certain amount themselves in order to advance to the next stage.
  INDIVIDUAL_THRESHOLD = 'INDIVIDUAL_THRESHOLD',
  // Everyone on the client's family plan must contribute a collective amount to advance to the next stage.
  FAMILY_THRESHOLD = 'FAMILY_THRESHOLD',
  // Either the client themselves can contribute a certain amount, or everyone on the client's family plan
  // can contribute a certain amount to advance to the next stage (whichever comes first).
  INDIVIDUAL_OR_FAMILY_THRESHOLD = 'INDIVIDUAL_OR_FAMILY_THRESHOLD',
  // There is no advancing past this stage.
  NONE = 'NONE',
}

/**
 * Describes a stage of a client's insurance plan, based on the kinds of payments they make during it.
 */
export interface InsuranceStage {
  // The kind of payment the client is making during this stage.
  paymentStructure: PaymentStructure;
  // The criteria that will determine how the client moves to the next insurance stage.
  stageCompletionCriteria: StageCompletionCriteria;
  // If this is true, then the client has already completed this stage by paying a sufficient amount into their plan.
  completed: boolean;
}

/**
 * Returns true if the eligibility lookup is a special case that indicates something wrong with
 * the eligibility lookup. There are two distinct special cases:
 * 1. All values are nullish. Our billing system technically treats this as fully covered when
 *    confirming appointments, but it is likely erroneous.
 * 2. Both coinsurance and copay exist. Our billing system takes coinsurance over copay if they
 *    both exist, but it should probably be manually confirmed.
 */
export const isExceptionalCase = (lookup: EligibilityLookupRead): boolean => {
  return (
    isNil(lookup.inNetworkOutOfPocketMax) &&
    isNil(lookup.familyInNetworkOutOfPocketMax) &&
    isNil(lookup.inNetworkDeductible) &&
    isNil(lookup.familyInNetworkDeductible) &&
    isNil(lookup.inNetworkCopay) &&
    isNil(lookup.inNetworkCoinsurance)
  );
};

/**
 * Describes the given eligibility lookup as a sequence of stages, where the client has a different
 * payment structure in each stage.
 */
export const getInNetworkInsuranceStages = (
  lookup: EligibilityLookupRead
): InsuranceStage[] => {
  const stages: InsuranceStage[] = [];
  const { inNetworkCopay, inNetworkCoinsurance } = lookup;

  if (isExceptionalCase(lookup)) {
    return [
      {
        paymentStructure: PaymentStructure.UNKNOWN,
        stageCompletionCriteria: StageCompletionCriteria.NONE,
        completed: false,
      },
    ];
  }

  const individualDeductibleRemaining =
    getRemainingIndividualInNetworkDeductible(lookup);
  const familyDeductibleRemaining =
    getRemainingFamilyInNetworkDeductible(lookup);
  const remainingIndividualOutOfPocketCosts =
    getRemainingIndividualOutOfPocketCosts(lookup);
  const remainingFamilyOutOfPocketCosts =
    getRemainingFamilyOutOfPocketCosts(lookup);
  const oopMaxMet =
    remainingIndividualOutOfPocketCosts === 0 ||
    remainingFamilyOutOfPocketCosts === 0;
  // Check for deductibles.
  if (
    !isNil(individualDeductibleRemaining) &&
    !isNil(familyDeductibleRemaining)
  ) {
    stages.push({
      paymentStructure: PaymentStructure.DEDUCTIBLE,
      stageCompletionCriteria:
        StageCompletionCriteria.INDIVIDUAL_OR_FAMILY_THRESHOLD,
      completed:
        individualDeductibleRemaining === 0 ||
        familyDeductibleRemaining === 0 ||
        oopMaxMet,
    });
  } else if (!isNil(individualDeductibleRemaining)) {
    stages.push({
      paymentStructure: PaymentStructure.DEDUCTIBLE,
      stageCompletionCriteria: StageCompletionCriteria.INDIVIDUAL_THRESHOLD,
      completed: individualDeductibleRemaining === 0 || oopMaxMet,
    });
  } else if (!isNil(familyDeductibleRemaining)) {
    stages.push({
      paymentStructure: PaymentStructure.DEDUCTIBLE,
      stageCompletionCriteria: StageCompletionCriteria.FAMILY_THRESHOLD,
      completed: familyDeductibleRemaining === 0 || oopMaxMet,
    });
  }

  // Next check for coinsurance or copay, as long as the deductible != the OOP max.
  if (
    !(stages.length && canDeductibleBeUsedAsOopMax(lookup)) &&
    (inNetworkCoinsurance || inNetworkCopay)
  ) {
    let paymentStructure;
    if (!!inNetworkCoinsurance && !!inNetworkCopay) {
      paymentStructure = PaymentStructure.COPAY_COINSURANCE;
    } else if (inNetworkCoinsurance) {
      paymentStructure = PaymentStructure.COINSURANCE;
    } else {
      paymentStructure = PaymentStructure.COPAY;
    }
    if (
      remainingIndividualOutOfPocketCosts !== undefined &&
      remainingFamilyOutOfPocketCosts !== undefined
    ) {
      stages.push({
        paymentStructure,
        stageCompletionCriteria:
          StageCompletionCriteria.INDIVIDUAL_OR_FAMILY_THRESHOLD,
        completed: oopMaxMet,
      });
    } else if (remainingIndividualOutOfPocketCosts !== undefined) {
      stages.push({
        paymentStructure,
        stageCompletionCriteria: StageCompletionCriteria.INDIVIDUAL_THRESHOLD,
        completed: oopMaxMet,
      });
    } else if (remainingFamilyOutOfPocketCosts !== undefined) {
      stages.push({
        paymentStructure,
        stageCompletionCriteria: StageCompletionCriteria.FAMILY_THRESHOLD,
        completed: oopMaxMet,
      });
    } else {
      stages.push({
        paymentStructure,
        stageCompletionCriteria: StageCompletionCriteria.NONE,
        completed: false,
      });
    }
  }

  if (
    !stages.length ||
    (stages.length &&
      stages[stages.length - 1].stageCompletionCriteria !==
        StageCompletionCriteria.NONE)
  ) {
    stages.push({
      paymentStructure: PaymentStructure.FULLY_COVERED,
      stageCompletionCriteria: StageCompletionCriteria.NONE,
      completed: false,
    });
  }

  return stages;
};

/**
 * Returns true if the eligibility lookup indicates that a person's deductible can be treated like
 * an out-of-pocket max.
 * Does not distinguish between family and individual deductibles.
 */
export const canDeductibleBeUsedAsOopMax = ({
  inNetworkDeductible,
  inNetworkCoinsurance,
  inNetworkCopay,
  inNetworkOutOfPocketMax,
  inNetworkOutOfPocketApplied,
  inNetworkDeductibleApplied,
  familyInNetworkDeductible,
  familyInNetworkOutOfPocketMax,
  familyInNetworkOutOfPocketApplied,
  familyInNetworkDeductibleApplied,
}: EligibilityLookupRead): boolean => {
  const deductibleEqualsOopMax =
    (inNetworkDeductible || 0) === (inNetworkOutOfPocketMax || 0) &&
    (familyInNetworkDeductible || 0) === (familyInNetworkOutOfPocketMax || 0);
  // In some uncommon cases, the deductible applied is not a subset of the out of pocket applied,
  // even if the limits are the same. When this happens, we can't treat the deductible like an OOP
  // max, because they might still have OOP remaining after reaching the deductible.
  const willNeedToPayAfterReachingDeductible =
    ((inNetworkDeductibleApplied || 0) > (inNetworkOutOfPocketApplied || 0) ||
      (familyInNetworkDeductibleApplied || 0) >
        (familyInNetworkOutOfPocketApplied || 0)) &&
    (inNetworkCoinsurance || inNetworkCopay);
  const onlyHasDeductible =
    !inNetworkCoinsurance &&
    !inNetworkCopay &&
    !inNetworkOutOfPocketMax &&
    !familyInNetworkOutOfPocketMax;
  return (
    (deductibleEqualsOopMax && !willNeedToPayAfterReachingDeductible) ||
    onlyHasDeductible
  );
};

/**
 * Returns the remaining amount needed to reach the individual deductible, if it exists.
 * If an individual deductible does not exist, returns undefined (even if a family deductible exists).
 */
export const getRemainingIndividualInNetworkDeductible = (
  lookup: EligibilityLookupRead
): number | undefined => {
  return lookup.inNetworkDeductible
    ? Math.max(
        lookup.inNetworkDeductible - (lookup.inNetworkDeductibleApplied || 0),
        0
      )
    : undefined;
};

/**
 * Returns the remaining amount needed to reach the family deductible, if it exists.
 * If a family deductible does not exist, returns undefined (even if an individual deductible exists).
 */
export const getRemainingFamilyInNetworkDeductible = (
  lookup: EligibilityLookupRead
): number | undefined => {
  return lookup.familyInNetworkDeductible
    ? Math.max(
        lookup.familyInNetworkDeductible -
          (lookup.familyInNetworkDeductibleApplied || 0),
        0
      )
    : undefined;
};

/**
 * Returns the amount of money that must be spent on individual costs before the client starts being
 * fully covered.
 * If no individual OOP max exists, returns undefined (even if a family OOP max exists).
 */
export const getRemainingIndividualOutOfPocketCosts = (
  lookup: EligibilityLookupRead
): number | undefined => {
  // Special case: patient is fully covered if none of these exist.
  if (
    !lookup.inNetworkDeductible &&
    !lookup.familyInNetworkDeductible &&
    !lookup.inNetworkCoinsurance &&
    !lookup.inNetworkCopay
  ) {
    return 0;
  }
  let max = lookup.inNetworkOutOfPocketMax;
  let applied = lookup.inNetworkOutOfPocketApplied;
  if (!max && canDeductibleBeUsedAsOopMax(lookup)) {
    max = lookup.inNetworkDeductible;
    applied = lookup.inNetworkDeductibleApplied;
  }
  return max ? Math.max(max - (applied || 0), 0) : undefined;
};

/**
 * Returns the amount of money that must be spent by the client or their family before the client
 * starts being fully covered.
 * If no family OOP max exists, returns undefined (even if an individual OOP max exists).
 */
export const getRemainingFamilyOutOfPocketCosts = (
  lookup: EligibilityLookupRead
): number | undefined => {
  // Special case: patient is fully covered if none of these exist.
  if (
    !lookup.inNetworkDeductible &&
    !lookup.familyInNetworkDeductible &&
    !lookup.inNetworkCoinsurance &&
    !lookup.inNetworkCopay
  ) {
    return 0;
  }
  let max = lookup.familyInNetworkOutOfPocketMax;
  let applied = lookup.familyInNetworkOutOfPocketApplied;
  if (!max && canDeductibleBeUsedAsOopMax(lookup)) {
    max = lookup.familyInNetworkDeductible;
    applied = lookup.familyInNetworkDeductibleApplied;
  }
  return max ? Math.max(max - (applied || 0), 0) : undefined;
};

export const getFamilyInNetworkOutOfPocketMax = (
  lookup: EligibilityLookupRead
): number | undefined => {
  if (
    !lookup.familyInNetworkOutOfPocketMax &&
    canDeductibleBeUsedAsOopMax(lookup)
  ) {
    return lookup.familyInNetworkDeductible;
  }
  return lookup.familyInNetworkOutOfPocketMax;
};

export const getIndividualInNetworkOutOfPocketMax = (
  lookup: EligibilityLookupRead
): number | undefined => {
  if (!lookup.inNetworkOutOfPocketMax && canDeductibleBeUsedAsOopMax(lookup)) {
    return lookup.inNetworkDeductible;
  }
  return lookup.inNetworkOutOfPocketMax;
};

export const hasMetOutOfPocketMax = (
  lookup: EligibilityLookupRead
): boolean => {
  const remainingIndividualOutOfPocketCosts =
    getRemainingIndividualOutOfPocketCosts(lookup);
  const remainingFamilyOutOfPocketCosts =
    getRemainingFamilyOutOfPocketCosts(lookup);
  return (
    remainingIndividualOutOfPocketCosts === 0 ||
    remainingFamilyOutOfPocketCosts === 0
  );
};

export const getDeductibleUnknownDueToOverride = (
  lookup: EligibilityLookupRead,
  isFamily: boolean
) =>
  isFamily
    ? lookup.familyInNetworkDeductibleAccumulatorUnknown ||
      lookup.familyInNetworkDeductibleUnknown
    : lookup.inNetworkDeductibleAccumulatorUnknown ||
      lookup.inNetworkDeductibleUnknown;
export const getCarrierRegex = (carrierId: number) => {
  return (
    CARRIER_REGEX_MAP[carrierId] ||
    (isFrontEndCarrierIdBcbs(carrierId) ? BCBS_MEMBER_ID_PATTERN : undefined) ||
    DEFAULT_MEMBER_ID_PATTERN
  );
};
export const getCarrierHelperText = (carrierId: number) => {
  if (!!getCarrierRegex(carrierId).example) {
    return `Most member IDs for this insurance carrier look like ${
      getCarrierRegex(carrierId).example
    }; that being said, there can always be exceptions. Please double check the member ID to make sure it is
    exactly as it appears on the insurance card.`;
  } else {
    return `This member id does not match the pattern we would expect for this insurance carrier, but there can always be exceptions. Please double check the member id to make sure it is exactly as it appears on the insurance card.`;
  }
};

export const isValidMemberId = (memberId: string): boolean => {
  const validCharacters = DEFAULT_MEMBER_ID_PATTERN;
  return validCharacters.test(memberId);
};

export const isStatusInNetwork = (insuranceStatus?: any) => {
  if (!insuranceStatus) {
    return false;
  }
  return [
    PatientInsuranceOrEAPStatus.IN_NETWORK,
    PatientInsuranceOrEAPStatus.IN_NETWORK_PENDING_CREDENTIALING,
    PatientInsuranceOrEAPStatus.VIRTUAL_ONLY_NETWORK,
  ].includes(insuranceStatus);
};

// same as above but exclude IN_NETWORK_PENDING_CREDENTIALING -
// we want to let a patient schedule an appointment with a provider only if the cred date is past
// https://therapymatch.slack.com/archives/C02SW5UE81X/p1736524495572159?thread_ts=1736524097.449109&cid=C02SW5UE81X
export const isStatusInNetworkForScheduling = (insuranceStatus?: any) => {
  if (!insuranceStatus) {
    return false;
  }
  return [
    PatientInsuranceOrEAPStatus.IN_NETWORK,
    PatientInsuranceOrEAPStatus.VIRTUAL_ONLY_NETWORK,
  ].includes(insuranceStatus);
};

// Explicit check for OON statuses as there are statuses beyond
// just in-network and out-of-network. (!inNetwork is not always OON).
export const isStatusOON = (insuranceStatus?: any) => {
  if (!insuranceStatus) {
    return false;
  }
  return [
    PatientInsuranceOrEAPStatus.OUT_OF_NETWORK,
    PatientInsuranceOrEAPStatus.OUT_OF_NETWORK_NOT_LICENSED,
    PatientInsuranceOrEAPStatus.OUT_OF_NETWORK_NOT_CREDENTIALED_IN_PATIENT_STATE,
    PatientInsuranceOrEAPStatus.OUT_OF_NETWORK_NOT_CREDENTIALED_IN_PROVIDER_ADDRESS_STATE,
  ].includes(insuranceStatus);
};

export const hasHeadwaySourcedHumanInputError = (
  readinessIssues: Array<InsuranceReadinessIssueInsufficientOrIncorrectInfo>
) => {
  return (
    readinessIssues.length > 0 &&
    readinessIssues[0].patientMismatchInputFields &&
    readinessIssues[0].patientMismatchInputFields.length > 0
  );
};

export const getHumanInputLookupIssues = (
  appointmentReadiness?: UserAppointmentReadiness | undefined
) => {
  if (!appointmentReadiness) {
    return [];
  }
  return (appointmentReadiness.insurance ?? [])
    .filter(
      ({ type }) =>
        type ===
        InsuranceReadinessIssueInsufficientOrIncorrectInfoType.INSUFFICIENT_OR_INCORRECT_INFORMATION
    )
    .map(
      (issue) => issue as InsuranceReadinessIssueInsufficientOrIncorrectInfo
    );
};

export const isUnready = (
  appointmentReadiness?: UserAppointmentReadiness
): boolean => {
  if (!appointmentReadiness) {
    return false;
  }
  const issues = appointmentReadiness.insurance || [];
  return issues.length > 0;
};

export const hasUnreadiness = (
  appointmentReadiness?: UserAppointmentReadiness,
  issueType?: any
): boolean => {
  if (!appointmentReadiness) {
    return false;
  }
  const issues = appointmentReadiness.insurance || [];
  return issues.some((issue) => issue.type === issueType);
};

export const hasInactiveBenefitsUnreadiness = (
  appointmentReadiness?: UserAppointmentReadiness
): boolean => {
  return hasUnreadiness(
    appointmentReadiness,
    InsuranceReadinessIssueInactiveBenefitsType.INACTIVE_BENEFITS
  );
};

export const hasFrozenForCOBUnreadiness = (
  appointmentReadiness?: UserAppointmentReadiness
): boolean => {
  return hasUnreadiness(
    appointmentReadiness,
    InsuranceReadinessIssueFrozenForCoordinationOfBenefitsType.FROZEN_FOR_COB
  );
};

export const hasFrozenForCOBUserActionUnreadiness = (
  appointmentReadiness?: UserAppointmentReadiness
): boolean => {
  return hasUnreadiness(
    appointmentReadiness,
    InsuranceReadinessIssueFrozenForCoordinationOfBenefitsAwaitingUserActionType.FROZEN_FOR_COB_USER_ACTION
  );
};

export const hasOONUnreadiness = (
  appointmentReadiness?: UserAppointmentReadiness
): boolean => {
  return hasUnreadiness(
    appointmentReadiness,
    InsuranceReadinessIssueOutOfNetworkType.OUT_OF_NETWORK
  );
};

export const hasNoRemainingSessionsUnreadiness = (
  appointmentReadiness?: UserAppointmentReadiness
): boolean => {
  return hasUnreadiness(
    appointmentReadiness,
    InsuranceReadinessNoRemainingSessionsType.NO_REMAINING_COVERED_SESSIONS
  );
};

export const hasRemainingSessions = (
  appointmentReadiness?: UserAppointmentReadiness
): boolean => {
  return hasUnreadiness(
    appointmentReadiness,
    InsuranceReadinessRemainingSessionsType.REMAINING_COVERED_SESSIONS
  );
};

export const hasApproachingRemainingSessions = (
  appointmentReadiness?: UserAppointmentReadiness
): boolean => {
  return hasUnreadiness(
    appointmentReadiness,
    InsuranceReadinessApproachingMaxSessionsType.APPROACHING_MAX_SESSIONS
  );
};

export const hasTerminatedUnreadiness = (
  appointmentReadiness?: UserAppointmentReadiness
): boolean => {
  return hasUnreadiness(
    appointmentReadiness,
    InsuranceReadinessIssueTerminatedType.TERMINATED
  );
};

export const hasNoMentalHealthBenefitsUnreadiness = (
  appointmentReadiness?: UserAppointmentReadiness
): boolean => {
  return hasUnreadiness(
    appointmentReadiness,
    InsuranceReadinessIssueNoMentalHealthBenefitsType.HAS_NO_MENTAL_HEALTH_BENEFITS
  );
};

export const hasManualVerificationRequiredUnreadiness = (
  appointmentReadiness?: UserAppointmentReadiness
): boolean => {
  return hasUnreadiness(
    appointmentReadiness,
    InsuranceReadinessIssueManualVerificationRequiredType.MANUAL_VERIFICATION_REQUIRED
  );
};

export const hasNoTelehealthBenefitsUnreadiness = (
  appointmentReadiness?: UserAppointmentReadiness
): boolean => {
  return hasUnreadiness(
    appointmentReadiness,
    InsuranceReadinessIssueHasNoTelehealthBenefitsType.HAS_NO_TELEHEALTH_BENEFITS
  );
};

export const isIneligible = (
  eligibilityLookup?: EligibilityLookupRead | EligibilityLookupNested
): boolean => {
  const isIneligible =
    eligibilityLookup?.terminated ||
    eligibilityLookup?.outOfNetwork ||
    eligibilityLookup?.hasNoMentalHealthBenefits ||
    eligibilityLookup?.manualVerificationRequired;
  return !!isIneligible;
};

/* used for eventing */
export const getIneligibleLookupTypeFromReadinessIssues = (
  issues: UserAppointmentReadiness['insurance'] | null = [],
  eligibilityLookup?: EligibilityLookupRead | undefined
) => {
  const issuesByType = issues ? issues.map((issue) => issue.type) : [];
  if (
    issuesByType.includes(
      InsuranceReadinessIssueOutOfNetworkType.OUT_OF_NETWORK
    ) ||
    eligibilityLookup?.outOfNetwork
  ) {
    return 'out_of_network';
  } else if (
    issuesByType.includes(
      InsuranceReadinessIssueManualVerificationRequiredType.MANUAL_VERIFICATION_REQUIRED
    ) ||
    eligibilityLookup?.manualVerificationRequired
  ) {
    return 'manual_verification_required';
  } else if (
    issuesByType.includes(InsuranceReadinessIssueTerminatedType.TERMINATED) ||
    eligibilityLookup?.terminated
  ) {
    return 'terminated';
  } else if (
    issuesByType.includes(
      InsuranceReadinessIssueNoMentalHealthBenefitsType.HAS_NO_MENTAL_HEALTH_BENEFITS
    ) ||
    eligibilityLookup?.hasNoMentalHealthBenefits
  ) {
    return 'has_no_mental_health_benefits';
  }
  return 'outage';
};

interface CostShareStageDescription {
  header: string;
  description: string;
}

export const getCostShareStageDescription = (
  eligibilityLookup: EligibilityLookupRead
): CostShareStageDescription | undefined => {
  const stages = getInNetworkInsuranceStages(eligibilityLookup);
  const currentStageIdx = stages.findIndex((stage) => !stage.completed);
  const currentStage = stages[currentStageIdx];

  if (!currentStage) {
    return undefined;
  }

  let header: string;
  let description: string | undefined;

  switch (currentStage.paymentStructure) {
    case PaymentStructure.DEDUCTIBLE:
      header = "Right now, you're paying the full session cost.";
      description =
        "You'll pay the insurance rate for your sessions — which is typically lower than paying out of pocket.";
      break;
    case PaymentStructure.COPAY:
      header = `Right now, you're paying a ${formatPrice(
        eligibilityLookup.inNetworkCopay
      )} copay.`;
      description = `You'll pay a flat rate of ${formatPrice(
        eligibilityLookup.inNetworkCopay
      )}, and your insurance will cover the rest of the session costs.`;
      break;
    case PaymentStructure.COINSURANCE:
      const percent = (
        (eligibilityLookup.inNetworkCoinsurance || 0) * 100
      ).toFixed(0);
      header = `Right now, you're paying ${percent}% in coinsurance.`;
      description = `You'll pay ${percent}% of the cost per session, and your insurance will cover the rest of the session costs.`;
      break;
    case PaymentStructure.COPAY_COINSURANCE:
      const coinsurancePercentage =
        (eligibilityLookup.inNetworkCoinsurance || 0) * 100;
      const copay = formatPrice(eligibilityLookup.inNetworkCopay);
      header = `Right now, you're paying ${copay} in copay and ${coinsurancePercentage.toFixed(
        0
      )}% in coinsurance.`;
      description = `You'll pay a ${copay} copay and ${coinsurancePercentage.toFixed(
        0
      )}% of the remaining session cost. Your insurance will cover the remaining ${(
        100 - coinsurancePercentage
      ).toFixed(0)}%.`;
      break;
    case PaymentStructure.FULLY_COVERED:
      header = "You're paying $0 for sessions.";
      if (
        getRemainingIndividualOutOfPocketCosts(eligibilityLookup) === 0 &&
        stages.length > 1
      ) {
        description = `You've met your ${formatPrice(
          getIndividualInNetworkOutOfPocketMax(eligibilityLookup)
        )} out-of-pocket maximum, so your insurance will cover all costs while your plan is active.`;
      } else if (
        getRemainingFamilyOutOfPocketCosts(eligibilityLookup) === 0 &&
        stages.length > 1
      ) {
        description = `Your family met their ${formatPrice(
          getFamilyInNetworkOutOfPocketMax(eligibilityLookup)
        )} out-of-pocket maximum, so your insurance will cover all costs while your plan is active.`;
      } else {
        description =
          'Your sessions are fully covered while your plan is still active.';
      }
      break;
    case PaymentStructure.UNKNOWN:
      return undefined;
    default:
      checkExhaustive(currentStage.paymentStructure);
  }

  return {
    header: header,
    description: description,
  };
};

/*
To display in the past appointment card, to explain in english 
why the estimated cost was what it was. 
*/
export const getPastAppointmentCostEstimateDescription = (
  eligibilityLookup: EligibilityLookupRead | undefined
): string => {
  if (!eligibilityLookup) {
    return `Your estimated cost is based on everything we know about your plan and our relationship with your insurer`;
  }

  const stages = getInNetworkInsuranceStages(eligibilityLookup);
  const currentStageIdx = stages.findIndex((stage) => !stage.completed);
  const currentStage = stages[currentStageIdx];
  if (!currentStage) {
    return `Your estimated cost is based on everything we know about your plan and our relationship with your insurer`;
  }

  switch (currentStage.paymentStructure) {
    case PaymentStructure.DEDUCTIBLE:
      return 'Your estimated cost is the full cost per session since you haven’t met your deductible yet.';
    case PaymentStructure.COPAY:
      return `Your estimated cost is your copay, and your insurance covers the rest.`;
    case PaymentStructure.COINSURANCE:
      const percent = (
        (eligibilityLookup.inNetworkCoinsurance || 0) * 100
      ).toFixed(0);
      return `Your estimated cost is ${percent}%  of the cost per session, and your insurance will cover the rest of the session costs.`;
    case PaymentStructure.COPAY_COINSURANCE:
      const coinsurancePercentage =
        (eligibilityLookup.inNetworkCoinsurance || 0) * 100;
      const copay = formatPrice(eligibilityLookup.inNetworkCopay);
      return `Your estimated cost is ${copay} in copay and ${coinsurancePercentage.toFixed(
        0
      )}% in coinsurance. Your insurance will cover the remaining ${(
        100 - coinsurancePercentage
      ).toFixed(0)}%.`;
    case PaymentStructure.FULLY_COVERED:
      if (
        getRemainingIndividualOutOfPocketCosts(eligibilityLookup) === 0 &&
        stages.length > 1
      ) {
        return `You don’t need to pay a session cost since you’ve already met your ${formatPrice(
          getIndividualInNetworkOutOfPocketMax(eligibilityLookup)
        )} out-of-pocket maximum for this plan year.`;
      } else if (
        getRemainingFamilyOutOfPocketCosts(eligibilityLookup) === 0 &&
        stages.length > 1
      ) {
        return `You don’t need to pay a session cost since your family already met their ${formatPrice(
          getFamilyInNetworkOutOfPocketMax(eligibilityLookup)
        )} out-of-pocket maximum for this plan year.`;
      } else {
        return 'Your sessions are fully covered while your plan is still active.';
      }
    case PaymentStructure.UNKNOWN:
      return `Your estimated cost is based on everything we know about your plan and our relationship with your insurer`;
    default:
      checkExhaustive(currentStage.paymentStructure);
  }
};

export const isUserInsuranceMedicareOrMedicaid = (
  latestEligibilityLookup?: EligibilityLookupNested
) => {
  return (
    latestEligibilityLookup?.isMedicare || latestEligibilityLookup?.isMedicaid
  );
};

export const isClientMedicareOrMedicaid = (
  client?: UserRead | NestedPatientReadForCalendar
) => {
  return isClientMedicare(client) || isClientMedicaid(client) || false;
};

export const isClientMedicare = (
  client?: UserRead | NestedPatientReadForCalendar
) => {
  const eligibilityLookup =
    client?.activeUserInsurance?.latestEligibilityLookup;
  return eligibilityLookup?.isMedicare || false;
};

export const isClientMedicaid = (
  client?: UserRead | NestedPatientReadForCalendar
) => {
  return client?.activeUserInsurance?.isMedicaid || false;
};

/**
 *
 * @param client user whose active insurance we are checking
 * @param month 0-based month number to check if the client's plan is resetting in that month, defaults to next month
 * @returns boolean If the client's plan is resetting in the given month
 */
export const isPlanResettingSoon = (
  client: UserRead,
  withinDays: number = 30
): boolean => {
  // if month not specified, default to next month
  const now = moment();
  const presumedResetDate = client?.activeUserInsurance?.presumedPlanResetDate;
  if (!presumedResetDate) {
    return false;
  }
  // presumedPlanResetDate in format 'MM-DD' so set year to current year
  const presumedResetMoment = moment(presumedResetDate).set('year', now.year());
  // If date has already passed, assume reset date is next year
  if (now.isAfter(presumedResetMoment)) {
    presumedResetMoment.set('year', now.year() + 1);
  }

  const withinDaysFromNow = moment().add(withinDays, 'days');
  return presumedResetMoment.isBetween(now, withinDaysFromNow, 'day', '[]');
};
